exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-career-linked-data-scientist-index-tsx": () => import("./../../../src/pages/career/linked-data-scientist/index.tsx" /* webpackChunkName: "component---src-pages-career-linked-data-scientist-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-release-notes-index-tsx": () => import("./../../../src/pages/release-notes/index.tsx" /* webpackChunkName: "component---src-pages-release-notes-index-tsx" */),
  "component---src-pages-subscriptions-index-tsx": () => import("./../../../src/pages/subscriptions/index.tsx" /* webpackChunkName: "component---src-pages-subscriptions-index-tsx" */),
  "component---src-pages-triplydb-index-tsx": () => import("./../../../src/pages/triplydb/index.tsx" /* webpackChunkName: "component---src-pages-triplydb-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

